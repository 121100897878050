import React from 'react';
export const Premint = () => {




    return (
        <>
        <div className='pdfWrapper'>
            <iframe title= "Plantoids Mint Structure" src="https://drive.google.com/file/d/1-A86O51kaz5aO7LYYWo6uSsadZuZfllJ/preview" allow="autoplay" ></iframe>
        </div>
        </>
    );
}