import React from 'react';
export const Pdf = () => {




    return (
        <>
        <div className='pdfWrapper'>
            <iframe title= "Plantoids extended Roadmap" src="https://drive.google.com/file/d/1rlxZ5-hNFVPzFvgZ5buENUVVjK-7-ddC/preview" allow="autoplay" ></iframe>
        </div>
        </>
    );
}